import 'react-app-polyfill/ie9'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider as StoreProvider } from 'react-redux'
// import { PersistGate } from 'redux-persist/integration/react'

import './index.css'
import './utils/fonts/fonts.css'
import 'normalize.css'
import App from './App'
import configureStore from './store'

// import registerServiceWorker from './registerServiceWorker'

// const store = configureStore().store
const store = configureStore()

ReactDOM.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      {/* <PersistGate persistor={configureStore().persistor}> */}
      <App />
      {/* </PersistGate> */}
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById('root')
)
