import { takeLatest, all, call, put } from 'redux-saga/effects'
import { SIGN_IN } from './index'
import { signInFail } from './index'
import { signIn } from './userApi'
import { setupToken } from 'utils/request'
import Cookie from 'js-cookie'

const isProductionEnv = process.env.NODE_ENV === 'production'
// const CrossStorageClientUrl = isProductionEnv
//   ? 'https://testportal.alphaone.ru/'
//   : 'http://localhost:3001'
const CrossStorageClientUrl = isProductionEnv
  ? 'https://testportal.alphaone.ru/'
  : 'http://local.alphaone.ru:3000'

const signInSaga = function*(action) {
  const { username, password } = action.payload
  let req = `username=${username}&password=${password}&grant_type=password`
  try {
    const data = yield call(signIn, req)
    yield call(saveToken, data)
    localStorage.setItem('profile', data.userName)
    yield call((window.location = CrossStorageClientUrl))
  } catch (error) {
    yield put(signInFail(error.response.data.error_description))
  }
}

export function saveToken(data) {
  setupToken(data.access_token)
  localStorage.setItem('token', data.access_token)
  Cookie.set('accessToken', data.access_token, {
    path: '/',
    domain: '.alphaone.ru'
  })
  Cookie.set('username', data.userName, { path: '/', domain: '.alphaone.ru' })
}

export default function*() {
  yield all([takeLatest(SIGN_IN, signInSaga)])
}
