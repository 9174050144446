import React, { useEffect, useState, lazy, Suspense } from 'react'

import { withRouter } from 'react-router-dom'
import { CrossStorageHub } from 'cross-storage'

// import LandingMobile from './pages/LandingMobile'
// import MainPage from 'pages/mainPage'

import { ModalProvider } from 'utils/context'

// import PageWrapper from 'modules/pageWrapper'

const PageWrapper = lazy(() => import('modules/pageWrapper'))
const MainPage = lazy(() => import('pages/mainPage'))
const LandingMobile = lazy(() => import('pages/LandingMobile'))

const isProductionEnv = process.env.NODE_ENV === 'production'
// const CrossStorageClientUrl = isProductionEnv
//   ? 'testportal.alphaone.ru/'
//   : 'localhost:3001'
const CrossStorageClientUrl = isProductionEnv
  ? 'testportal.alphaone.ru/'
  : 'local.alphaone.ru:3000'

const CrossStorageClientUrlRegExp = new RegExp(
  `/://(www.)?${CrossStorageClientUrl}$/`
)

const App = ({ history }) => {
  const sections = [
    'main',
    'services',
    'partners',
    'news',
    'numbers',
    'contacts'
  ]

  // ---
  // device type state
  // ---
  const [isMobile, setDeviceType] = useState(null)
  // ---
  // is modal open (& which one) state
  // ---
  const [isModal, setModal] = useState({ menu: false, news: false })
  // ---
  // scroll managment states
  // ---
  const [currentSection, setCurrentSection] = useState('#main')
  const [nextSection /*setNextSection*/] = useState(null)
  const [prevSection /*setPrevSection*/] = useState(null)

  // ---
  // detect device type
  // ---
  useEffect(() => {
    setDeviceType(window.innerWidth <= 1024)

    CrossStorageHub.init([
      { origin: CrossStorageClientUrlRegExp, allow: ['get', 'del'] }
    ])
  }, [])

  // ---
  // determine if one of the modals is being opened
  // ---
  const handleModal = modal => {
    if (modal === 'menu') {
      setModal({ ...isModal, menu: !isModal.menu })
    } else if (modal === 'news') {
      setModal({ ...isModal, news: !isModal.news })
    }
  }

  // ---
  // detect current section on scroll
  // ---
  useEffect(() => {
    setCurrentSection(history.location.hash)
  }, [history.location])

  const styleNavigation = navigation => {
    const dark =
      currentSection.includes('services') ||
      currentSection.includes('partners') ||
      currentSection.includes('numbers')

    if (dark) {
      navigation.classList.add('black')
    } else {
      navigation.classList.remove('black')
    }
  }

  return (
    <ModalProvider value={{ onModal: handleModal, isModal: isModal }}>
      <Suspense fallback={<div>Loading...</div>}>
        {isMobile ? (
          <PageWrapper
            history={history}
            isMobile={isMobile}
            sections={sections}
            manageSections={{
              currentSection: currentSection,
              nextSection: nextSection,
              prevSection: prevSection
            }}
          >
            <LandingMobile />
          </PageWrapper>
        ) : (
          <MainPage
            sections={sections}
            history={history}
            currentSection={currentSection}
            nextSection={nextSection}
            prevSection={prevSection}
            styleNavigation={styleNavigation}
          />
        )}
      </Suspense>
    </ModalProvider>
  )
}

export default withRouter(App)
