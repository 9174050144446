import { post } from 'utils/request'
// import axios from 'axios'

export const signIn = req =>
  post('/Authorize2/Token', req).then(response => response.data)

// export const signIn = req =>
//   axios
//     .post('https://alphaone.ru/Authorize2/Token', req)
//     .then(response => response.data)
